<template>
  <main :data-version="version" :data-release="release">
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script setup lang="ts">
import { RouterView } from "vue-router";
import { onBeforeUnmount, onMounted, ref } from "vue";

const vitePreloadError = ref(false);
const version = VITE_APP_VERSION;
const release = VITE_APP_RELEASE;

// Cuando se produce un deploy los cambios en las hash de los fichero estaticos
// vulven el sistema inestable. Este error salta en eso casos en los que el estatico apuntado
// ya no existe. Ej: pinchamos en filtros y no se abre nada.
// https://github.com/vitejs/vite/issues/11804
// https://vitejs.dev/guide/build#load-error-handling
onMounted(() => {
  window.addEventListener("vite:preloadError", handleVitePreLoadError);
  window.addEventListener("error", handleResourceError, true);
  window.addEventListener("error", handleError);
});

onBeforeUnmount(() => {
  window.removeEventListener("vite:preloadError", handleVitePreLoadError);
  window.removeEventListener("error", handleResourceError, true);
  window.removeEventListener("error", handleError);
});

function handleResourceError(event: Event) {
  const target = event.target as HTMLAnchorElement;
  if (target.tagName === "LINK" && target.rel === "stylesheet") {
    console.error("Css file load error:", target.href);
  }
}

function handleError(event: ErrorEvent) {
  console.error("Error capturado en window:", event.message, event);
}

function handleVitePreLoadError(event: Event) {
  vitePreloadError.value = true;
  const customEvent = event as CustomEvent;
  if (customEvent.detail?.url.endsWith(".css")) {
    console.error("Css preload file error:", customEvent.detail.url);
  } else {
    console.error("Preload file error:", customEvent);
  }
  vitePreloadError.value = true;
}
</script>

<style lang="postcss">
#app,
body,
html {
  @apply !bg-gray-100 !h-full;
}

main {
  height: 100%;
}
</style>
